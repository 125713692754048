import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
// import { TextField} from "@material-ui/core"
import axios from "axios"
import { toast } from "react-toastify"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhoneVolume, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { API_ROOT } from "gatsby-env-variables"
import { Link } from "gatsby"


class contactPage extends React.Component {
  state = {
    name: "",
    email: "",
    message: "",
    address: "",
    companyEmail: [],
    phone: [],
    pageTitle: 'Contact us',
    metaArr: [],
    errors: {
      name: "",
      email: "",
      message: "",
      mapSrc: ""
    }
  }
  componentDidMount(){
    if(window.$crisp && window.$crisp !== undefined){
      window.$crisp.push(['do', 'chat:hide']);
    }

    this.getSeoData();
    axios.get(API_ROOT+`/api/v1/settings`)
      .then( (response) => {
        if(response && response.data && response.data.data){
          this.setState({
            address: response.data.data.address,
            companyEmail: response.data.data.email,
            phone: response.data.data.phone,
            mapSrc: "https://maps.google.com/maps?q="+response.data.data.latitude+","+response.data.data.longitude+"&t=&z=13&ie=UTF8&iwloc=&output=embed"
          })
        }

      })
      .catch(function (error) {
      });
  }
  getSeoData(){
    axios.get(API_ROOT+`/api/v1/seo/contact_us`)
      .then( (response) => {

        var data = response?.data?.data || [];
        var metaArr = [];
        data.map((e) =>{
          if(e.type === 'title'){
            this.setState({
              pageTitle: e.value
            })
          } else {
            metaArr.push({
              name: e.type,
              content: e.value
            })
          }
        })

        this.setState({
          metaArr: metaArr
        })

      })
  }
  handleInputChange = event => {
    const target = event.target;
    const name = target.name;
    let value = target.value;
    this.setState({
      [name]: value
    })
  }
  validateForm = () => {
    let validate = false;
    let errors = this.state.errors;
    if(!this.state.name){
      errors.name = 'Required Filed';
      validate = false;
    } else {
      errors.name = '';
      validate = true;
    }
    if(!this.state.email){
      errors.email = 'Required Filed';
      validate = false;
    } else {
      errors.email = '';
      validate = true;
    }
    if(!this.state.message){
      errors.message = 'Required Filed';
      validate = false;
    } else {
      errors.message = '';
      validate = true;
    }
    this.setState({errors : errors})
    return validate
  }
  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validateForm()){
      var requestObj = this.state;
      delete requestObj.errors
      axios.post(API_ROOT+`/api/v1/contacts`,
        requestObj
      )
        .then(function (response) {
          toast.success('successfully send.')
        })
        .catch(function (error) {
          toast.error('an error occured.')
        });
    }
  }
  render() {
    return (
      <Layout pageInfo={{ pageName: "Contact us" }}>
        <SEO title={this.state.pageTitle}  meta = {this.state.metaArr} />
        <Container fluid className={'inline-pages'}>
          <Row className={'text-center'}>
            <Col className={'inline-pages-title'} md={{span:10, offset:1}}>
              <p>Contact us</p>
            </Col>
            <Col className={'inline-pages-border'} md={{span:10, offset:1}}></Col>
            <Col md={{span:10, offset:1}} className={'top-z-index p0'}>
              <p className={'bread-crumb'}>
                <Link to="/" className="link-no-style">
                  &nbsp;Home &nbsp;
                </Link>
                /  Contact us
              </p>
            </Col>
          </Row>
          <Row>
            <Col className={'inline-pages-content-box'} md={{span:10, offset:1}}>
              <Row>
                <Col md={'7'}>
                  <div className="mapouter">
                    <div className="gmap_canvas">
                      <iframe width="100%" height="100%" id="gmap_canvas" title="mapFrame"
                              src={this.state.mapSrc}
                              frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                      <a href="https://www.emojilib.com"></a>
                    </div>
                  </div>
                </Col>
                <Col md={'5'}>
                  <p className={'contact-text-title'}>Contact us</p>
                  <div>
                    <FontAwesomeIcon className={'green-color'} icon={faMapMarkerAlt} />
                    <span className={'map-title'}> Adress: </span>
                    <p className={'map-description ml40 mt5'}>{this.state.address}</p>
                  </div>
                  <div>
                    <FontAwesomeIcon className={'green-color'} icon={faEnvelope} />
                    <span className={'map-title'}> Email: </span>
                    <p className={'map-description ml45 mt5'}>
                      {this.state.companyEmail && this.state.companyEmail.map((email, i) => (
                        <span className={'mb0'} key={i}>{email}<br /></span>
                      ))}
                    </p>
                  </div>
                  <div>
                    <FontAwesomeIcon className={'green-color'} icon={faPhoneVolume} />
                    <span className={'map-title'}> Phone: </span>
                    <p className={'map-description ml40 mt5'}>
                      {this.state.phone && this.state.phone.map((phone, i) => (
                        <span className={'mb0'} key={i}>{phone}<br /></span>
                      ))}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className={'contact-form'}>
                <Col md={{span:12}}>
                  <form onSubmit={this.handleSubmit}>
                    <Row>
                      <Col className={'mb20'} md={{span:10, offset:1}}>
                        <p className={'contact-form-title'}>Contact Form</p>
                      </Col>
                      <Col className={'mb20'} md={{span:5, offset:1}}>
                        {/* <TextField
                          label={'Name'}
                          className={'col-md-12'}
                          name="name"
                          value={this.state.name}
                          onChange={this.handleInputChange}
                          error={this.state.errors.name !== ''}
                          helperText={this.state.errors.name}
                          required
                        /> */}
                      </Col>
                      <Col className={'mb20'} md={{span:5}}>
                        {/* <TextField
                          label={'Email'}
                          className={'col-md-12'}
                          name="email"
                          value={this.state.email}
                          onChange={this.handleInputChange}
                          error={this.state.errors.email !== ''}
                          helperText={this.state.errors.email}
                          required
                        /> */}
                      </Col>
                      <Col className={'mb20'} md={{span:10, offset:1}}>
                        {/* <TextField
                          label={'Message'}
                          multiline
                          className={'col-md-12'}
                          name="message"
                          value={this.state.message}
                          onChange={this.handleInputChange}
                          error={this.state.errors.message !== ''}
                          helperText={this.state.errors.message}
                          required
                        /> */}
                      </Col>
                      <Col className={'mb50 text-center'} md={{span:12}}>
                        <button type='submit' className={'submit-btn'} onClick={this.handleSubmit}>
                          Send
                        </button>
                      </Col>
                    </Row>
                  </form>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}
export default contactPage
